@font-face {
	font-family: "ArialNarrow-web";
	src: url("../fonts/ArialNarrow-web.woff2") format("woff2"),
		url("../fonts/ArialNarrow-web.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "ArialNarrow-web";
	src: url("../fonts/ArialNarrowBold-web.woff2") format("woff2"),
		url("../fonts/ArialNarrowBold-web.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

html {
	font-family: "ArialNarrow-web", sans-serif;
	font-size: 16px;
	letter-spacing: 0.5px;
}

#nav {
	letter-spacing: 1px;
}

html {
	height: 100%;
}
body {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	max-width: 1530px;
	margin: 0 auto;
}

footer {
	margin-top: auto;
}

.blueprint * {
	background-color: rgba(255, 0, 0, 0.02);
}

// Video player
.video {
	position: relative;
	video {
		width: 100%;
	}
	&__play {
		width: 50px;
		height: 50px;
		margin-left: -25px;
		margin-top: -25px;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 1;
		transition: opacity 0.3s;
		background-color: #fff;
		&:focus {
			outline: none;
		}
		svg {
			width: 100%;
			height: auto;
		}
		svg:last-child {
			display: none;
		}
		&.is-active {
			svg:first-child {
				display: none;
			}
			svg:last-child {
				display: block;
			}
		}
		&.is-inactive {
			opacity: 0;
		}
	}
	&__full {
		position: absolute;
		right: 18px;
		bottom: 18px;
	}
}

.title-underline {
	display: block;
	position: relative;
	&::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: -2px;
		border-top: 2px solid #000;
		width: 84px;
		height: 1px;
		transition: width 0.1s;
	}
}
.underline-simple-animation {
	&::after {
		width: 30px;
	}
}

.related-project:hover .underline-simple-animation {
	&::after {
		width: 84px;
	}
}

.underline-animation {
	width: 84px;
	height: 20px;
	border-top: 2px solid;
	border-color: #000;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		left: 100%;
		top: -2px;
		background-color: #000;
		width: 2px;
		height: 2px;
		transition: height 0.1s;
	}
	&::after {
		content: "";
		position: absolute;
		left: 100%;
		bottom: -2px;
		border-top: 2px solid #000;
		width: 0;
		height: 1px;
		transition: width 0.1s;
	}
}

@media (min-width: 1024px) {
	.animated-title.top-down .underline-animation {
		&::before {
			height: 20px;
		}
	}
	.animated-title.left-right .underline-animation {
		&::after {
			width: 30px;
		}
	}
}

.media-item {
	position: relative;
	display: inline-block;
	line-height: 1.5;
}

.media-item-container {
	&:hover {
		.media-item {
			text-decoration: underline;
			text-decoration-thickness: 2px;
			text-decoration-skip-ink: none;
			text-underline-position: under;
		}
	}
	figure {
		max-width: 420px;
		max-height: 420px;
		img {
			max-width: inherit;
			max-height: inherit;
			margin: 0 auto;
		}
		@media (max-width: 768px) {
			display: none !important;
		}
	}
}

#nav-toggle {
	outline: none;
}

.le_grid {
	display: grid;
	grid-template-columns: min-content 1fr;
}
